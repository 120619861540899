import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import LandingSection from '../components/LandingSection'
import SelectLevelCard from '../components/cards/SelectLevelCard'
import Layout from '../layouts'

import api from '../backend'

import styles from './index.module.scss'
import { navigate } from 'gatsby'

const IndexPage = () => {
  useEffect(() => {
    // reroute to /user if we're logged in (stats fails if not)
    // api.stats()
    //  .then(() => navigate('/user'))
  }, [])

  return (<Layout>
    <div className={styles.landing}>
      <LandingSection />
      <SelectLevelCard />
    </div>
    <div className={`${styles.container} ${styles.landingSectionWhite}`}>
      <div className={styles.row}>
        <div className={styles.colMd6}>
          <h2>Kanji&nbsp;lernen – auf&nbsp;Deutsch.</h2>
          <h3>Etwa 2300 Kanji und 6000 Vokabeln</h3>
          <p>Alle offiziellen Schulkanji, Jōyō-Kanji und Kanji, die für den JLPT N1 relevant sind.</p>
          <h3>Lesungen mit passenden Beispielen</h3>
          <p>On-yomi und Kun-yomi werden mit Beispielwörtern trainiert, die deinem Lernniveau entsprechen.</p>
          <h3>Bedeutungen, Radikale und Übersetzungen</h3>
          <p>Beim Lernen siehst du passende Beispielwörter, Sätze, Übersetzungen sowie die zugehörigen Radikale.</p>
        </div>
        <div className={styles.colMd6}>
          <img className={styles.screenshots} src="/style/kanji-trainer-deutsch-cotsu.png" alt="Beispielscreenshots" />
        </div>
      </div>
    </div>
    <Footer />
  </Layout>)
}

export default IndexPage
